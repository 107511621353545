import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Masonry from 'react-masonry-component';
import ReactHtmlParser from 'react-html-parser';
import ScrollAnimation from 'react-animate-on-scroll';

const masonryOptions = {
  transitionDuration: '0.5s',
  isAnimated: true,
};

const imagesLoadedOptions = { background: '.my-bg-image-el' };
const JobOverview = ({ data }) => {
  JobOverview.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
  };

  const childElements = () => {
    return (
      data.Data.Jobs.map &&
      data.Data.Jobs.map(jobItem => (
        <div className="job-item">
          {jobItem.JobTitle && <h3>{jobItem.JobTitle}</h3>}
          {jobItem.IntroductionText &&
            ReactHtmlParser(jobItem.IntroductionText)}
          {jobItem.Url && (
            <Link to={jobItem.Url} className="readmorelink-underline">
              {jobItem.ReadMoreLinkText && jobItem.ReadMoreLinkText}
            </Link>
          )}
        </div>
      ))
    );
  };
  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      offset={10}
      duration={1.5}
      animateOnce
    >
      <div className="jobs-list">
        <Masonry
          className="my-gallery-class" // default ''
          elementType="div" // default 'div'
          options={masonryOptions} // default {}
          disableImagesLoaded={false} // default false
          updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
          imagesLoadedOptions={imagesLoadedOptions}
        >
          {childElements()}
        </Masonry>
      </div>
    </ScrollAnimation>
  );
};
export default JobOverview;
