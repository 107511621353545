import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SettingsContext from '../../context/SettingsContext';

const Language = ({ changeLanguageHandler, currentCulture }) => {
  Language.propTypes = {
    changeLanguageHandler: PropTypes.func.isRequired,
    currentCulture: PropTypes.string.isRequired,
  };
  const settings = useContext(SettingsContext);
  return (
    <span className="language">
      <ul>
        {settings.languageArr &&
          settings.languageArr.map(lang => (
            <li
              key={lang.Name && lang.Name}
              className={
                currentCulture.toLowerCase() === lang.Code.toLowerCase()
                  ? `active ${lang.Name.toLowerCase()}`
                  : `${lang.Name.toLowerCase()}`
              }
            >
              <button
                type="button"
                onClick={() => changeLanguageHandler(lang.Code)}
              >
                {lang.Name}
              </button>
            </li>
          ))}
      </ul>
    </span>
  );
};
export default Language;
