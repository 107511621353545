import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import DownloadForm from '../forms/DownloadForm';
import getResponsiveImageUrl from '../../utils/getResponsiveImageUrl';

const HeaderBlog = ({ data, guid, currentCulture }) => {
  HeaderBlog.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
    guid: PropTypes.string.isRequired,
    currentCulture: PropTypes.string.isRequired,
  };
  const [dowloadform, setDownLoad] = useState(false);
  const [closethankyou, setThankyou] = useState(false);
  const openform = () => {
    setDownLoad(true);
    setThankyou(true);
  };
  const closeDownLoad = () => {
    setDownLoad(false);
    setThankyou(true);
  };
  return (
    <div>
      <div className="blogimage">
        <img
          src={
            getResponsiveImageUrl(data.Data.Image) &&
            getResponsiveImageUrl(data.Data.Image)
          }
          alt=""
        />
      </div>
      <div className="header-text">
        <div
          className={`container-md ${
            data.Data.ShowDownloadButton ? 'downloadlink' : ''
          }`}
        >
          {data.Data.Title && (
            <h1
              className={`common-header ${data.Data.Brush && data.Data.Brush} ${
                data.Data.BackgroundPattern
              }`}
            >
              {data.Data.Title}
            </h1>
          )}
          <div className="blog-details">
            <div className="toprow">
              {data.Data.DisplayDate && (
                <div className="date">
                  {data.Data.DisplayDate}
                  &nbsp;-&nbsp;&nbsp;
                </div>
              )}
              <div className="author">
                {data.Data.Author && (
                  <Link
                    to={data.Data.Author.Url && data.Data.Author.Url}
                    className="readmorelink-underline"
                  >
                    {data.Data.Author.FirstName && data.Data.Author.FirstName}
                    &nbsp;
                    {data.Data.Author.LastName && data.Data.Author.LastName}
                  </Link>
                )}
              </div>
            </div>
            {data.Data.ReadingTime && (
              <div className="bottomrow">
                ± &nbsp;
                {data.Data.ReadingTime}
              </div>
            )}
          </div>
          <div className="common-main-des">
            <div className="box" />
            {data.Data.IntroText && ReactHtmlParser(data.Data.IntroText)}
          </div>
          {data.Data.ShowDownloadButton && (
            <div
              className={`button-wrapper ${
                !dowloadform ? 'fadeIn' : 'fadeOut'
              }`}
            >
              <button
                key="download"
                className=""
                type="button"
                onClick={() => openform()}
              >
                {data.Data.DownloadInfo.DownloadButtonText}
              </button>
            </div>
          )}
          {/* {dowloadform && ( */}
          <div className={dowloadform ? 'fadeIn' : 'fadeOut'}>
            <DownloadForm
              data={data}
              guid={guid}
              currentCulture={currentCulture}
              closeDownLoad={closeDownLoad}
              closethankyou={closethankyou}
            />
          </div>
          {/* )} */}
        </div>
      </div>
    </div>
  );
};
export default HeaderBlog;
