import React, { useContext } from 'react';
import SettingsContext from '../../context/SettingsContext';

const Socialicons = () => {
  const settings = useContext(SettingsContext);
  return (
    <div className="social-icons">
      {settings.footerArray.SocialLinks &&
        settings.footerArray.SocialLinks.map(
          social =>
            social.Url && (
              <a
                key={social.ClassName}
                href={social.Url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className={social.ClassName} />
              </a>
            )
        )}
    </div>
  );
};
export default Socialicons;
