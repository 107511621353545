import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import SettingsContext from '../../context/SettingsContext';

const Mainnav = () => {
  const settings = useContext(SettingsContext);
  return (
    <ul>
      {settings.navigation &&
        settings.navigation.map(link => (
          <li className={link.SubMenuItems.length ? 'submenuparent' : ''}>
            <Link to={link.MenuItem.Url}>{link.MenuItem.Name}</Link>
            {link.NotifyCount !== 0 && (
              <span className="vacation-count">{link.NotifyCount}</span>
            )}
            {link.SubMenuItems.length ? (
              <ul className="submenu">
                {link.SubMenuItems.map(subm => (
                  <li key={subm.Name && subm.Name}>
                    <Link key={subm.Name && subm.Name} to={subm.Url}>
                      {subm.Name}
                    </Link>
                  </li>
                ))}
              </ul>
            ) : (
              ''
            )}
          </li>
        ))}
    </ul>
  );
};
export default Mainnav;
