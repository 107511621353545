const getResponsiveImageUrl = data => {
  const winWidth = window.innerWidth;
  if (!data) return '';
  if (winWidth < 768) {
    return data.ImageMobile;
  }
  if (winWidth < 1025) {
    return data.ImageTab;
  }
  if (winWidth < 1600) {
    return data.Image;
  }
  if (winWidth > 1600) {
    return data.DesktopLarge;
  }
  return data.Image;
};
export default getResponsiveImageUrl;
