import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import ScrollAnimation from 'react-animate-on-scroll';

const RteDescription = ({ data }) => {
  RteDescription.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
  };
  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      offset={10}
      duration={1.5}
      animateOnce
    >
      <div className="rte-text-wrapper">
        {data.Data.Title && (
          <h1 className="common-header">{data.Data.Title}</h1>
        )}
        <div className="rte-text">
          {data.Data.Description && ReactHtmlParser(data.Data.Description)}
        </div>
      </div>
    </ScrollAnimation>
  );
};
export default RteDescription;
