import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Masonry from 'react-masonry-component';
import ScrollAnimation from 'react-animate-on-scroll';
import getResponsiveImageUrl from '../../utils/getResponsiveImageUrl';

const masonryOptions = {
  transitionDuration: '0.5s',
  isAnimated: true,
  horizontalOrder: true,
  fitWidth: true,
};

const imagesLoadedOptions = { background: '.my-bg-image-el' };
const TeamOverview = ({ data }) => {
  TeamOverview.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
  };

  // const [headcount, setHeadcount] = useState([]);
  // const [randomNum, setRandom] = useState([]);
  useEffect(() => {
    // const randomImgSelect = () => {
    //   setHeadcount(data.Data.TeamMembars.length);
    //   setInterval(() => {
    //     setRandom(Math.floor(Math.random() * headcount) + 1);
    //     for (let i = 0; i <= headcount; i++) {
    //       if (i === randomNum) {
    //         document
    //           .getElementsByClassName('team-memeber')
    //           [i].classList.add('active');
    //       } else {
    //         document
    //           .getElementsByClassName('team-memeber')
    //           [i].classList.remove('active');
    //       }
    //     }
    //   }, 6000);
    // };
    // randomImgSelect();
  });
  const childElements = () => {
    return (
      data.Data.TeamMembars &&
      data.Data.TeamMembars.map(teammem => (
        <div className="team-memeber">
          <div className="team-inner">
            {teammem.HideDetailPage === false && (
              <Link to={teammem.Url}>
                <div
                  className="imageblock"
                  style={{
                    backgroundImage: `url(${getResponsiveImageUrl(
                      teammem.Image
                    )})`,
                  }}
                />
                <div className="name">
                  <p>
                    <span>
                      {teammem.FirstName && teammem.FirstName}
                      &nbsp;
                      {teammem.FirstName && teammem.LastName}
                    </span>
                  </p>
                </div>
                <div className="jobtitle">
                  <p>
                    <span>{teammem.FirstName && teammem.JobTitle}</span>
                  </p>
                </div>
              </Link>
            )}
            {teammem.HideDetailPage === true && (
              <div>
                <div
                  className="imageblock"
                  style={{
                    backgroundImage: `url(${getResponsiveImageUrl(
                      teammem.Image
                    )})`,
                  }}
                />
                <div className="name">
                  <p>
                    <span>
                      {teammem.FirstName && teammem.FirstName}
                      &nbsp;
                      {teammem.FirstName && teammem.LastName}
                    </span>
                  </p>
                </div>
                <div className="jobtitle">
                  <p>
                    <span>{teammem.FirstName && teammem.JobTitle}</span>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      ))
    );
  };
  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      offset={10}
      duration={1.5}
      animateOnce
    >
      <div className="team" id="team">
        <div className=" container-md">
          <Masonry
            className="my-gallery-class" // default ''
            elementType="div" // default 'div'
            options={masonryOptions} // default {}
            disableImagesLoaded={false} // default false
            updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
            imagesLoadedOptions={imagesLoadedOptions}
          >
            {childElements()}
          </Masonry>
        </div>
      </div>
    </ScrollAnimation>
  );
};
export default TeamOverview;
