import React from 'react';
import PropTypes from 'prop-types';
import ScrollAnimation from 'react-animate-on-scroll';
import getResponsiveImageUrl from '../../utils/getResponsiveImageUrl';

const CaseSmall = ({ data }) => {
  CaseSmall.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
  };
  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      offset={10}
      duration={1.5}
      animateOnce
      className="caseanimation-wrap"
    >
      {data.Data.CaseItems.length > 0 && (
        <div className="casesmall-wrapper">
          <h3 className="h3header">{data.Data.Title}</h3>
          {data.Data.CaseItems &&
            data.Data.CaseItems.map(caseItem => (
              <div className="casesmall">
                <div
                  className="imageblock"
                  style={{
                    backgroundImage: `url(${getResponsiveImageUrl(
                      caseItem.ImageThumbnail
                    )})`,
                  }}
                />
                <div className="content">
                  <div className="clientname">{caseItem.ClientName}</div>
                  <div className="projectname">
                    <p>
                      <span>{caseItem.ProjectName}</span>
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </ScrollAnimation>
  );
};
export default CaseSmall;
