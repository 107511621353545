import React, { useEffect, useState } from 'react';
import './scss/main.scss';
import axios from 'axios';
import { Router, Route, Switch } from 'react-router-dom';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Home from './container/Home';
import SettingsContext from './context/SettingsContext';
import history from './utils/history';
import 'animate.css/animate.min.css';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import returnDevice from './utils/detectDevice';
import ScrollToTop from './components/common/ScrollToTop';
import PageNotFound from './components/common/PageNotFound';

function App() {
  const [hamState, setHamState] = useState(false);
  const [mainNavigation, setMainNavigation] = useState([]);
  const [footer, setFooter] = useState([]);
  const [language, setLanguage] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState(
    window.umbCurrentLanguage
  );
  const [currentPageId, setCurrentPageId] = useState(window.umbCurrentPageId);
  const [address, setAddress] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [pageload, setPageLoad] = useState([true]);
  const [docType, setDocType] = useState([]);
  const [menuTheme, setMenuTheme] = useState([]);
  const [pageNotFoundId, setPageNotFoundId] = useState([]);
  const handleHamClick = () => {
    setHamState(!hamState);
  };

  useEffect(() => {
    setTimeout(() => {
      setPageLoad(false);
    }, 2000);
    axios
      .get('/Umbraco/Api/Page/GetMasterPageContent', {
        params: {
          culture: currentLanguage,
        },
      })
      .then(response => {
        if (response.status === 200) {
          setMainNavigation(response.data.Header.MainNavigation);
          setFooter(response.data.Footer);
          setLanguage(response.data.Languages);
          setAddress(response.data.Footer.Address);
          setRoutes(response.data.Routes);
          setPageNotFoundId(
            response.data.Routes[response.data.Routes.length - 1].Guid
          );
        }
      });
    history.listen(() => {
      setHamState(false);
    });
  }, [currentLanguage]);

  const changeCurrentLanguage = lang => {
    setCurrentLanguage(lang);
    axios
      .get('/Umbraco/Api/Page/GetPageUrl', {
        params: {
          culture: lang,
          pageId: currentPageId,
        },
      })
      .then(response => {
        if (response.status === 200) {
          history.push(response.data.Url);
        }
      });
  };

  const changeCurrentPageId = id => {
    setCurrentPageId(id);
  };
  return (
    <SettingsContext.Provider
      value={{
        navigation: mainNavigation,
        footerArray: footer,
        languageArr: language,
        addressArray: address,
      }}
    >
      <div className={`App daytime ${docType} ${menuTheme} ${returnDevice()}`}>
        <div className="wrapper">
          <Router history={history}>
            <div
              className={
                pageload
                  ? 'custom-preloader'
                  : 'custom-preloader removepreloader'
              }
            />
            <ScrollToTop>
              <Header
                changeLanguageHandler={changeCurrentLanguage}
                currentCulture={currentLanguage}
                docType={docType}
                hamState={hamState}
                handleHamClick={handleHamClick}
                menuTheme={menuTheme}
              />
              <Switch>
                {routes &&
                  routes.map(route => (
                    <Route
                      key={route.Guid}
                      exact
                      path={route.Path}
                      render={props => (
                        <Home
                          guid={route.Guid}
                          currentCulture={currentLanguage}
                          changeCurrentPageId={changeCurrentPageId}
                          passDocType={setDocType}
                          passMenuTheme={setMenuTheme}
                          {...props}
                        />
                      )}
                    />
                  ))}
                <Route path="*">
                  <PageNotFound
                    pageNotFoundId={pageNotFoundId}
                    currentLanguage={currentLanguage}
                  />
                </Route>
              </Switch>

              <Footer />
            </ScrollToTop>
          </Router>
        </div>
      </div>
    </SettingsContext.Provider>
  );
}
export default App;
