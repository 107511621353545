import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import getResponsiveImageUrl from '../../utils/getResponsiveImageUrl';

const BlogCarousel = ({ data }) => {
  BlogCarousel.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
  };
  const [infiniteLoop, setInfinite] = useState([true]);
  useEffect(() => {
    console.log(data.Data.Blogs.length);
    if (data.Data.Blogs.length < 3) {
      setInfinite(false);
    } else {
      setInfinite(true);
    }
  }, []);
  const settings = {
    dots: true,
    className: 'center',
    centerMode: true,
    infinite: infiniteLoop,
    speed: 500,
    slidesToScroll: 3,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          className: 'center',
          centerMode: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      offset={10}
      duration={1.5}
      animateOnce
    >
      <div className="blog-slider">
        <div className="imageslider-block">
          {data.Data.Title && (
            <div className="carosal-header">
              <h3 className="h3header">{data.Data.Title}</h3>
            </div>
          )}
          <div className="multiple-view">
            <Slider {...settings}>
              {data.Data.Blogs &&
                data.Data.Blogs.map(item => (
                  <div>
                    <Link to={item.Url && item.Url}>
                      {/* <img
                        src={
                          getResponsiveImageUrl(item.Image) &&
                          getResponsiveImageUrl(item.Image)
                        }
                        alt=""
                        key=""
                        className="slick-slide"
                      /> */}
                      <div
                        className="blogcarosalimg "
                        style={{
                          backgroundImage: `url(${getResponsiveImageUrl(
                            item.Image
                          )})`,
                        }}
                      />
                      <h4 className="h4header">{item.Title && item.Title}</h4>
                    </Link>
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </ScrollAnimation>
  );
};
export default BlogCarousel;
