import React, { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

const ContactForm = ({ data, guid, currentCulture, changeThankyouPage }) => {
  ContactForm.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
    guid: PropTypes.string.isRequired,
    currentCulture: PropTypes.string.isRequired,
    changeThankyouPage: PropTypes.func.isRequired,
  };
  const tempArr = [];
  const onCheckBoxChange = e => {
    if (e.target.checked === true) {
      tempArr.push(e.target.name);
    } else {
      tempArr.pop(e.target.name);
    }
  };
  return (
    <div className="contactform-wrapper">
      <div className="contactform">
        <Formik
          initialValues={{
            email: '',
            name: '',
            message: '',
            interests: '',
            phone: '',
            organisation: '',
          }}
          validate={values => {
            const errors = {};
            if (!values.email) {
              errors.email =
                data.Data.DictionaryValues.ErrorName &&
                data.Data.DictionaryValues.ErrorName;
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email =
                data.Data.DictionaryValues.ErrorEmail &&
                data.Data.DictionaryValues.ErrorEmail;
            }
            if (!values.name) {
              errors.name =
                data.Data.DictionaryValues.ErrorName &&
                data.Data.DictionaryValues.ErrorName;
            }
            if (!values.message) {
              errors.message =
                data.Data.DictionaryValues.ErrorMessage &&
                data.Data.DictionaryValues.ErrorMessage;
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              axios({
                method: 'post',
                url: '/Umbraco/Api/Page/SendContactPageMail',
                params: {
                  pageId: guid,
                  name: values.name,
                  organisation: values.organisation,
                  interests: tempArr ? tempArr.toString() : '',
                  email: values.email,
                  phone: values.phone,
                  message: values.message,
                  culture: currentCulture,
                },
              });
              setSubmitting(true);
            }, 400);
            changeThankyouPage(true);
            window.scrollTo(0, 0);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <div>
              {
                <div className={isSubmitting ? '' : ''}>
                  <form onSubmit={handleSubmit} isSubmitting>
                    {data.Data.DictionaryValues && (
                      <div className="form-row">
                        <span>
                          {data.Data.DictionaryValues.Name &&
                            data.Data.DictionaryValues.Name}
                        </span>
                        <input
                          type="text"
                          name="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          className={
                            touched.name && errors.name ? 'input-error' : ''
                          }
                        />
                        {touched.name && errors.name && (
                          <div className="close" />
                        )}
                        <span className="error">
                          {errors.name && touched.name && errors.name}
                        </span>
                      </div>
                    )}
                    {data.Data.DictionaryValues && (
                      <div className="form-row">
                        <span>
                          {data.Data.DictionaryValues.Organisation &&
                            data.Data.DictionaryValues.Organisation}
                        </span>
                        <input
                          type="text"
                          name="organisation"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.organisation}
                        />
                      </div>
                    )}
                    {data.Data.DictionaryValues && (
                      <div className="form-row">
                        <span>
                          {data.Data.DictionaryValues.Interests &&
                            data.Data.DictionaryValues.Interests}
                        </span>
                        <div className="checkbox-wrap">
                          <span className="checkbox-row">
                            <label className="container">
                              Platforms
                              <input
                                type="checkbox"
                                name="platforms"
                                onBlur={handleBlur}
                                onChange={onCheckBoxChange}
                                value={values.platforms}
                              />
                              <span className="checkmark" />
                            </label>
                          </span>
                          <span className="checkbox-row">
                            <label className="container">
                              Campagnes
                              <input
                                type="checkbox"
                                name="campagnes"
                                onBlur={handleBlur}
                                onChange={onCheckBoxChange}
                                value={values.campagnes}
                              />
                              <span className="checkmark" />
                            </label>
                          </span>
                          <span className="checkbox-row">
                            <label className="container">
                              E-commerce
                              <input
                                type="checkbox"
                                name="ecommerce"
                                onBlur={handleBlur}
                                onChange={onCheckBoxChange}
                                value={values.ecommerce}
                              />
                              <span className="checkmark" />
                            </label>
                          </span>
                          <span className="checkbox-row">
                            <label className="container">
                              Optimalisatie
                              <input
                                type="checkbox"
                                name="optimalisatie"
                                onBlur={handleBlur}
                                onChange={onCheckBoxChange}
                                value={values.optimalisatie}
                              />
                              <span className="checkmark" />
                            </label>
                          </span>
                        </div>
                      </div>
                    )}
                    {data.Data.DictionaryValues && (
                      <div className="form-row">
                        <span>
                          {data.Data.DictionaryValues.Email &&
                            data.Data.DictionaryValues.Email}
                        </span>
                        <input
                          type="email"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          className={
                            errors.email && touched.email ? 'input-error' : ''
                          }
                        />
                        {errors.email && touched.email && (
                          <div className="close" />
                        )}
                        <span className="error">
                          {errors.email && touched.email && errors.email}
                        </span>
                      </div>
                    )}
                    {data.Data.DictionaryValues && (
                      <div className="form-row">
                        <span>
                          {data.Data.DictionaryValues.Phone &&
                            data.Data.DictionaryValues.Phone}
                        </span>
                        <input
                          type="text"
                          name="phone"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone}
                        />
                      </div>
                    )}
                    {data.Data.DictionaryValues && (
                      <div className="form-row">
                        <span>
                          {data.Data.DictionaryValues.Message &&
                            data.Data.DictionaryValues.Message}
                        </span>
                        <textarea
                          name="message"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.message}
                          className={
                            errors.message && touched.message
                              ? 'input-error'
                              : ''
                          }
                        />
                        {errors.message && touched.message && (
                          <div className="close" />
                        )}
                        <span className="error">
                          {errors.message && touched.message && errors.message}
                        </span>
                      </div>
                    )}
                    {data.Data.DictionaryValues && (
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className={isSubmitting ? 'disabled' : ''}
                      >
                        {data.Data.DictionaryValues.Send &&
                          data.Data.DictionaryValues.Send}
                      </button>
                    )}
                  </form>
                </div>
              }
            </div>
          )}
        </Formik>
        {/* {!closethankyou && (
          <div
            className={`thankyou-message-outer ${!closethankyou ? '' : 'show'}`}
          >
            <div className="thankyou-message">
              <button
                className="btn-close"
                type="button"
                onClick={() => closeContThankYou()}
              />
              {data.Data.ThankYouText &&
                ReactHtmlParser(data.Data.ThankYouText)}
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};
export default ContactForm;
