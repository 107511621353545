import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ScrollAnimation from 'react-animate-on-scroll';
import getMeta from '../../utils/getMeta';

const SocialShare = ({ data }) => {
  SocialShare.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
  };
  const [url, setUrl] = useState([]);
  useEffect(() => {
    setUrl(window.location.href);
  });
  const facebookShare = () => {
    const descr = getMeta('ogdescription');
    const image = getMeta('ogimage');
    const winWidth = 520;
    const winHeight = 350;
    const winTop = window.innerHeight / 2 - winHeight / 2;
    const winLeft = window.innerWidth / 2 - winWidth / 2;
    const fullurl = `http://www.facebook.com/share.php?u=${encodeURIComponent(
      url
    )}&picture=${encodeURIComponent(image)}&t=${encodeURIComponent(descr)}`;
    window.open(
      fullurl,
      'fbShareWindow',
      `height=450, width=550, top=${winTop}, left=${winLeft}, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0`
    );
    return false;
  };
  const twitterShare = () => {
    const descr = getMeta('ogdescription');
    const fullurl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      url
    )}&text=${encodeURIComponent(descr)}`;
    window.open(fullurl, 'TwitterWindow');
  };
  const linkedinShare = () => {
    const title = getMeta('ogtitle');
    const descr = getMeta('ogdescription');
    const image = getMeta('image');
    const fullurl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
      url
    )}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(
      descr
    )}&media=${encodeURIComponent(image)}`;
    window.open(fullurl, 'LinkedinWindow');
  };

  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      offset={10}
      duration={1.5}
      animateOnce
    >
      <div className="social-share">
        <div className="social-share-inner">
          <h4 className="h4header">{data.Data.Title}</h4>
          <ul>
            <li className="facebook">
              <button type="button" onClick={() => facebookShare()} />
            </li>
            <li className="twitter">
              <button type="button" onClick={() => twitterShare()} />
            </li>
            <li className="linkedin">
              <button type="button" onClick={() => linkedinShare()} />
            </li>
          </ul>
        </div>
      </div>
    </ScrollAnimation>
  );
};
export default SocialShare;
