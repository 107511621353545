// $(document).ready(function () {
//     var gameDivTag = document.getElementById("gameArea");
//     pongGame.init(gameDivTag);
// });
import Phaser from '../../phaser';

window.myscore = '0';

var pongGame = {
    //common variables
    winW: 0,
    winH: 0,
    game: null,
    paddle1: null,
    paddle2: null,
    ball: null,
    ball_launched: null,
    ball_velocity: null,
    score1_txt: null,
    score2_txt: null,
    score1: null,
    score2: null,
    gameDiv: null,
    startNum: 0,
    currentNum: 0,
    timeInterval: 0,

    //intial function
    init: function (gameDivTag) {
        pongGame.gameDiv = gameDivTag;
        pongGame.winW = pongGame.gameDiv.offsetWidth - 200;
        pongGame.winH = pongGame.gameDiv.offsetHeight - 60;
        pongGame.gameFlow();
    },
    gameFlow: function () {
        pongGame.gameDiv.querySelector("#startGame").onclick = function () {
            pongGame.gameDiv.querySelector("#pongStartContent").classList.add("inactive");
            pongGame.gameDiv.querySelector("#paddleLeft").style.display = "none";
            pongGame.gameDiv.querySelector("#paddleRight").style.display = "none";
            setTimeout(function () {
                pongGame.gameInitialize();
            }, 1000)
        };
        pongGame.gameDiv.querySelector("#playAgain").onclick = function () {
            pongGame.gameDiv.querySelector("#pongGameOver").classList.add("inactive");
            setTimeout(function () {
                pongGame.gameInitialize();
            }, 1000);
        };
    },
    gameInitialize: function () {
        pongGame.gameDiv.classList.remove("inactive");
        pongGame.game = new Phaser.Game(pongGame.winW, pongGame.winH, Phaser.AUTO, 'gameArea', {
            preload: pongGame.preload,
            create: pongGame.create,
            update: pongGame.update
        });
    },
    preload: function () {
        pongGame.game.load.image('paddle', './static/media/paddle.png');
        pongGame.game.load.image('ball', './static/media/ball5.png');
    },
    create: function () {
        pongGame.game.stage.setBackgroundColor(0xFF232525);
        //create paddle1 
        this.debug = true;
        pongGame.ball_launched = false;
        pongGame.ball_velocity = 500;
        pongGame.paddle1 = pongGame.createPaddle(0, pongGame.game.world.centerY);
        pongGame.paddle2 = pongGame.createPaddle(pongGame.game.world.width - 8, pongGame.game.world.centerY);
        pongGame.initializeCounter();
        pongGame.ball = pongGame.createBall(pongGame.game.world.centerX, pongGame.game.world.centerY);
        pongGame.launchBall();
        //pongGame.game.input.onDown.add(pongGame.launch_ball, this);
        pongGame.score1_text = pongGame.game.add.text(pongGame.game.world.centerX - 25, 30, '0', {
            font: "56px CeraPro-Bold",
            fill: "#fafafa",
            align: "center",
        })
        pongGame.score1 = 0;
        pongGame.score2 = 0;
    },
    update: function () {
        pongGame.score1_text.text = pongGame.score1;
        pongGame.controlPaddle(pongGame.paddle1, pongGame.game.input.y);

        //checking whether the paddles collide with the ball;
        pongGame.game.physics.arcade.collide(pongGame.paddle2, pongGame.ball);

        if (pongGame.game.physics.arcade.collide(pongGame.paddle1, pongGame.ball)) {
            pongGame.gameDiv.classList.remove("inactive");
            pongGame.gameDiv.classList.add("active");
            pongGame.score1_text.fill = '#65e1d4';
            setTimeout(function () {
                pongGame.score1_text.fill = '#fafafa';
                pongGame.gameDiv.classList.remove("active");
            }, 500);
            pongGame.score1 += 1;

        }
        console.log(pongGame.ball.body.velocity.y);
        pongGame.paddle2.body.velocity.setTo(pongGame.ball.body.velocity.y);
        pongGame.paddle2.body.velocity.x = 0;
        pongGame.paddle2.body.maxVelocity.y = 500;


        if (pongGame.ball.body.blocked.left) {
            pongGame.game.destroy();
            document.getElementById("pongResultLabel").innerHTML = pongGame.score1;
            //set gameover content
            document.getElementById("pongGameOver").classList.remove("inactive");
            document.getElementById("pongGameOver").classList.add("active");
            //set the gamearea content
            pongGame.gameDiv.classList.remove("active");
            pongGame.gameDiv.classList.add("inactive");
            pongGame.score2 += 1;
            window.myscore = pongGame.score1;
            console.log(pongGame.game)
            return;
        }

    },

    createPaddle: function (x, y) {
        var paddle = pongGame.game.add.sprite(x, y, 'paddle');
        //upperleft: 0, 0 and bottomright: 1, 1 of the image 
        paddle.anchor.setTo(0.5, 0.5);
        pongGame.game.physics.arcade.enable(paddle);
        //keeps the paddle from going past the game bounds
        paddle.body.collideWorldBounds = true;
        //stop any misbehaviours
        paddle.body.immovable = true;
        //scaling the paddles
        paddle.scale.setTo(0.5, 0.4);
        return paddle;
    },

    controlPaddle: function (paddle, y) {
        paddle.y = y;
        if (paddle.y < paddle.height / 2) {
            paddle.y = paddle.height / 2;
        } else if (paddle.y > pongGame.game.world.height - paddle.height / 2) {
            paddle.y = pongGame.game.world.height - paddle.height / 2;
        }
    },

    createBall: function (x, y) {
        var ball = pongGame.game.add.sprite(x, y, 'ball');
        ball.anchor.setTo(0.5, 0.5);
        pongGame.game.physics.arcade.enable(ball);
        ball.body.collideWorldBounds = true;
        // check on the speed of the ball once it bounces
        ball.body.bounce.setTo(1, 1);
        return ball;
    },

    launchBall: function () {
        //initial postion of the ball
        if (pongGame.ball_launched) {
            pongGame.resetBall();
        } else {
            setTimeout(function () {
                pongGame.releaseBall();
            }, 4000)
            // pongGame.releaseBall();
        }
    },

    resetBall: function () {
        pongGame.ball.x = pongGame.game.world.centerX;
        pongGame.ball.y = pongGame.game.world.centerY;
        pongGame.ball.body.velocity.setTo(0, 0);
        pongGame.ball_launched = false;
    },

    releaseBall: function () {
        pongGame.ball.body.velocity.x = -(pongGame.ball_velocity);
        pongGame.ball.body.velocity.y = pongGame.ball_velocity;
        pongGame.ball_launched = true;
    },

    endGame: function () {
        pongGame.resetBall();
        pongGame.controlPaddle(pongGame.paddle1, 0)
    },

    initializeCounter: function () {
        var timeleft = 4;
        var downloadTimer = setInterval(function () {
            timeleft--;
            document.getElementById("countdown").innerHTML = timeleft;
            if (timeleft == 0) {
                clearInterval(downloadTimer);
                // pongGame.gameInitialize();
                document.getElementById("countdown").innerHTML = "";
                return;
            }
        }, 1000);
    }
}

export default pongGame;