import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import ScrollAnimation from 'react-animate-on-scroll';
import getResponsiveImageUrl from '../../utils/getResponsiveImageUrl';

const ContentOverview = ({ data }) => {
  ContentOverview.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
  };
  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      offset={10}
      duration={1.5}
      animateOnce
    >
      <div className="overview-block">
        {data.Data.ContentItems &&
          data.Data.ContentItems.map(item => (
            <div className="rte-text-wrapper">
              {item.MenuLable && (
                <span className="menulabel">{item.MenuLable}</span>
              )}
              {item.Header.Title && (
                <h3 className="h3header">{item.Header.Title}</h3>
              )}
              {getResponsiveImageUrl(item.ThumbnailImage) && (
                <img src={getResponsiveImageUrl(item.ThumbnailImage)} alt="" />
              )}
              <div className="rte-text">
                {item.Header.Description &&
                  ReactHtmlParser(item.Header.Description)}
              </div>
              <Link
                to={item.Url && item.Url}
                className="readmorelink-underline"
              >
                {item.ReadMoreLinkText && item.ReadMoreLinkText}
              </Link>
            </div>
          ))}
      </div>
    </ScrollAnimation>
  );
};
export default ContentOverview;
