import React from 'react';
import PropTypes from 'prop-types';
import getResponsiveImageUrl from '../../utils/getResponsiveImageUrl';

const OneBlock = ({ data }) => {
  OneBlock.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
  };

  return (
    <div>
      <div className="three-block-wrapper">
        <div className="one-block">
          {getResponsiveImageUrl(data[0]) && (
            <img src={getResponsiveImageUrl(data[0])} alt="" />
          )}
        </div>
      </div>
    </div>
  );
};
export default OneBlock;
