import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import ScrollAnimation from 'react-animate-on-scroll';

const TeamMemberDetails = ({ data }) => {
  TeamMemberDetails.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
  };
  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      offset={10}
      duration={1.5}
      animateOnce
    >
      {data.Data.Title && data.Data.Description && (
        <div className="rte-text-wrapper">
          {data.Data.Title && <h3 className="h3header">{data.Data.Title}</h3>}
          <div className="rte-text">
            {data.Data.Description && ReactHtmlParser(data.Data.Description)}
          </div>
          {data.Data.BulletTitle && (
            <h4 className="h4header">{data.Data.BulletTitle}</h4>
          )}
          {data.Data.BulletFields && (
            <ul>
              {data.Data.BulletFields.map(bulItem => (
                <li>{bulItem}</li>
              ))}
            </ul>
          )}
        </div>
      )}
    </ScrollAnimation>
  );
};
export default TeamMemberDetails;
