import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Homecontent from '../components/homecontent/Homecontent';
import Video from '../components/video/Video';
import Services from '../components/services/Services';
import Case from '../components/cases/Case';
import Vacancies from '../components/jobs/Jobs';
import Clients from '../components/clients/Clients';
import Headingtext from '../components/headingtext/HeadingText';
import FilterOptions from '../components/filteroptions/FilterOptions';
import CaseDetailsTop from '../components/cases/CaseDetailsTop';
import CaseContenBlock from '../components/cases/CaseContenBlock';
import ImageBlocks from '../components/cases/ImageBlocks';
import CaseResults from '../components/cases/CaseResults';
import WideImageBlock from '../components/cases/WideImageBlock';
import ContactBlock from '../components/common/ContactBlock';
import HeaderBigIntro from '../components/common/HeaderBigIntro';
import JobOverview from '../components/jobs/JobOverview';
import RteDescription from '../components/common/RteDescription';
import Instagram from '../components/instagram/Instagram';
import QuoteText from '../components/common/QuoteText';
import CaseSmall from '../components/common/CaseSmall';
import ImageSliderBlock from '../components/common/ImageSliderBlock';
import TeamOverview from '../components/team/TeamOverview';
import TeamMemberHeader from '../components/team/TeamMemberHeader';
import TeamMemberDetails from '../components/team/TeamMemberDetails';
import BlogOverview from '../components/blog/BlogOverview';
import SocialShare from '../components/blog/SocialShare';
import BlogCarousel from '../components/blog/BlogCarousel';
import HeaderBlog from '../components/blog/HeaderBlog';
import ContentOverview from '../components/common/ContentOverview';
import ContactPage from '../components/contact/ContactPage';
import Ponggame from '../components/ponggame/Ponggame';

const Home = props => {
  const { guid, changeCurrentPageId, passDocType, passMenuTheme } = props;
  const [caseFilter, setCaseFilter] = useState('All');
  const [transition, setTransition] = useState(true);
  changeCurrentPageId(guid);
  const [contentArray, setContent] = useState([]);
  const [casePattern, setCasePattern] = useState([]);
  const [videoPattern, setVideoPattern] = useState([]);
  useEffect(() => {
    setTimeout(() => {
      setTransition(false);
    }, 1000);
    axios
      .get('/Umbraco/Api/Page/GetPageContent', {
        params: {
          culture: props.currentCulture,
          pageId: props.guid,
        },
      })
      .then(response => {
        if (response.status === 200) {
          setContent(response.data.Template);
          const docType = response.data.DocumentType;
          const pgtitle = response.data.PageTitle;
          const pageName = response.data.Name;
          passDocType(docType);
          if (String(docType) === 'caseItem') {
            setCasePattern('green-spray');
          } else {
            setCasePattern('case-boxes-circle');
          }

          if (String(docType) === 'home') {
            setVideoPattern('dash-pattern');
          } else {
            setVideoPattern('video-angle-spary');
          }
          document.title = pgtitle || pageName;
        }
      });
  }, []);
  const renderComponent = data => {
    switch (data.Type) {
      case 'HeaderHome':
        return <Homecontent data={data} />;
      case 'VideoPlayer':
        return <Video data={data} videoPattern={videoPattern} />;
      case 'Services':
        return <Services data={data} />;
      case 'CasesBlock':
        return <Case data={data} caseFilter="All" casePattern={casePattern} />;
      case 'CaseOverview':
        return (
          <Case data={data} caseFilter={caseFilter} casePattern={casePattern} />
        );
      case 'JobBlock':
        return <Vacancies data={data} />;
      case 'BrandBox':
        return <Clients data={data} />;
      case 'Header2':
        return <Headingtext data={data} />;
      case 'CaseOverviewFilter':
        return (
          <FilterOptions
            data={data}
            setCaseFilter={setCaseFilter}
            caseFilter={caseFilter}
          />
        );
      case 'CaseItem':
        return <CaseDetailsTop data={data} passMenuTheme={passMenuTheme} />;
      case 'TextBlock':
        return <CaseContenBlock data={data} />;
      case 'CaseResult':
        return <CaseResults data={data} />;
      case 'ContactBlock':
        return <ContactBlock data={data} />;
      case 'ThreeImageBlock':
        return <ImageBlocks data={data} />;
      case 'WideImageBlock':
        return <WideImageBlock data={data} />;
      case 'Header_BigIntro':
        return <HeaderBigIntro data={data} />;
      case 'JobOverview':
        return <JobOverview data={data} />;
      case 'RTE_Description':
        return <RteDescription data={data} />;
      case 'QuoteText':
        return <QuoteText data={data} />;
      case 'InstaBlock':
        return <Instagram data={data.Data} />;
      case 'CasesBlock_2':
        return <CaseSmall data={data} />;
      case 'ImageSliderBlock':
        return <ImageSliderBlock data={data} />;
      case 'TeamOverview':
        return <TeamOverview data={data} />;
      case 'TeamMemberHeader':
        return <TeamMemberHeader data={data} />;
      case 'TeamMemberDetails':
        return <TeamMemberDetails data={data} />;
      case 'BlogOverview':
        return <BlogOverview data={data} />;
      case 'ShareBlock':
        return <SocialShare data={data} />;
      case 'BlogCarousel':
        return <BlogCarousel data={data} />;
      case 'Header_Blog':
        return (
          <HeaderBlog
            data={data}
            guid={guid}
            currentCulture={props.currentCulture}
          />
        );
      case 'ContentOverview':
        return <ContentOverview data={data} />;
      case 'ContactPage':
        return (
          <ContactPage
            data={data}
            guid={guid}
            currentCulture={props.currentCulture}
          />
        );
      case 'PongGame':
        return <Ponggame data={data} />;
      default:
        return <div />;
    }
  };
  return (
    <React.Fragment sendData>
      <div
        className={
          transition
            ? 'custom-transition'
            : 'custom-transition removetransition'
        }
      />
      {contentArray && contentArray.map(data => renderComponent(data))}
    </React.Fragment>
  );
};
Home.propTypes = {
  guid: PropTypes.string.isRequired,
  currentCulture: PropTypes.string.isRequired,
  changeCurrentPageId: PropTypes.func.isRequired,
  passDocType: PropTypes.func.isRequired,
  passMenuTheme: PropTypes.func.isRequired,
};
export default Home;
