import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import ScrollAnimation from 'react-animate-on-scroll';
import { Waypoint } from 'react-waypoint';

const Video = ({ data, videoPattern }) => {
  Video.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
  };
  const [shouldPlay, updatePlayState] = useState(false);
  const handleEnterViewport = () => {
    updatePlayState(true);
  };
  const handleExitViewport = () => {
    updatePlayState(false);
  };
  return (
    <ScrollAnimation animateIn="fadeIn" offset={100} duration={1} animateOnce>
      <div className={`video-container ${videoPattern}`}>
        <div className="video-wrap">
          <Waypoint onEnter={handleEnterViewport} onLeave={handleExitViewport}>
            <div className="video-wrapper">
              <ReactPlayer
                url={data.Data.VideoLink && data.Data.VideoLink}
                youtubeConfig={{ playerVars: { showinfo: 0 } }}
                playing={shouldPlay}
                controls="false"
                width="100%"
                height="100%"
                loop="true"
                muted="true"
              />
            </div>
          </Waypoint>
        </div>
      </div>
    </ScrollAnimation>
  );
};
export default Video;
