import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ThreeBlock from './ThreeBlock';
import TwoBlock from './TwoBlock';
import OneBlock from './OneBlock';

const ImageBlocks = ({ data }) => {
  ImageBlocks.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
  };
  const [count, setCount] = useState([]);
  const [imageArray, setImageArr] = useState([]);
  useEffect(() => {
    setCount(data.Data.ImageUrl.length);
    setImageArr(data.Data.ImageUrl);
  }, []);
  return (
    <div className="image-blocks">
      <div className="container-md">
        {count === 3 ? <ThreeBlock data={imageArray} /> : ''}
      </div>
      <div className="container-md">
        {count === 2 ? <TwoBlock data={imageArray} /> : ''}
      </div>
      <div className="container-md">
        {count === 1 ? <OneBlock data={imageArray} /> : ''}
      </div>
    </div>
  );
};
export default ImageBlocks;
