import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Tilt from 'react-tilt';
import Masonry from 'react-masonry-component';
import _ from 'lodash';
import ScrollAnimation from 'react-animate-on-scroll';
import ReactHtmlParser from 'react-html-parser';
import getResponsiveImageUrl from '../../utils/getResponsiveImageUrl';

const masonryOptions = {
  transitionDuration: '0.5s',
  isAnimated: true,
};
const imagesLoadedOptions = { background: '.my-bg-image-el' };
const Case = ({ data, caseFilter, casePattern }) => {
  Case.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
    caseFilter: PropTypes.string.isRequired,
    casePattern: PropTypes.string.isRequired,
  };

  const filterCases = () => {
    if (caseFilter === 'All') return data.Data.CaseItems;
    return _.filter(data.Data.CaseItems, caseItem => {
      if (caseItem.Tags && _.find(caseItem.Tags, { Key: caseFilter })) {
        return true;
      }
      return false;
    });
  };

  // const responsiveImage = imgArray => {
  //   switch (winWidth) {
  //     case winWidth > 1600:
  //       return imgArray.DesktopLarge;
  //     case winWidth > 1024 < 1599:
  //       return imgArray.Image;
  //     case winWidth > 768 < 1023:
  //       return imgArray.ImageMobile;
  //     case winWidth > 200 < 767:
  //       return imgArray.ImageTab;
  //     default:
  //       return '';
  //   }
  // };

  const childElements = () => {
    if (!filterCases()) return false;
    return filterCases().map(cases => (
      <Link to={cases.Url} className="case-link" key={cases.ClientName}>
        <Tilt className="Tilt" options={{ max: 5 }}>
          {cases.Award && (
            <div
              className="awards"
              style={{ backgroundColor: cases.Award.BackgroundColor }}
            >
              <div
                className="arrow-down"
                style={{ borderTopColor: cases.Award.BackgroundColor }}
              />
              {ReactHtmlParser(cases.Award.Title)}
              <div className="awordlogo">
                <img src={cases.Award.Logo} alt="" />
              </div>
            </div>
          )}
          <div
            className="case"
            style={{
              backgroundImage: `url(${getResponsiveImageUrl(
                cases.ImageThumbnail
              )})`,
            }}
          >
            <div className="content">
              <span className="client">
                <span>{cases.ClientName}</span>
              </span>
              <span className="tagline">
                <span>{cases.ProjectName}</span>
              </span>
            </div>
          </div>
        </Tilt>
      </Link>
    ));
  };
  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      offset={10}
      duration={1.5}
      animateOnce
      className="caseanimation-wrap"
    >
      {data.Data.CaseItems.length > 0 && (
        <div className="cases-container">
          <div className="container-md">
            {data.Data.Title ? (
              <h2 className="main-title">
                {data.Data.Title && data.Data.Title}
              </h2>
            ) : (
              ''
            )}
            {data.Data.Slogan ? (
              <h3 className="sub-title">
                {data.Data.Slogan && data.Data.Slogan}
              </h3>
            ) : (
              ''
            )}
            <div className={`case-boxes ${casePattern}`}>
              <Masonry
                className="my-gallery-class" // default ''
                elementType="div" // default 'div'
                options={masonryOptions} // default {}
                disableImagesLoaded={false} // default false
                updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                imagesLoadedOptions={imagesLoadedOptions} // default {}
              >
                {childElements()}
              </Masonry>
            </div>
          </div>
        </div>
      )}
    </ScrollAnimation>
  );
};
export default Case;
