import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import ScrollAnimation from 'react-animate-on-scroll';

const HeaderBigIntro = ({ data }) => {
  HeaderBigIntro.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
  };
  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      offset={10}
      duration={1.5}
      animateOnce
    >
      <div className="container-md">
        {data.Data.Title && (
          <h1
            className={`common-header ${data.Data.Brush && data.Data.Brush} ${
              data.Data.BackgroundPattern
            }`}
          >
            {data.Data.Title}
          </h1>
        )}
        <div className="common-main-des">
          <div className="box" />
          {data.Data.Description && ReactHtmlParser(data.Data.Description)}
        </div>
      </div>
    </ScrollAnimation>
  );
};
export default HeaderBigIntro;
