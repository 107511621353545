import React from 'react';
import PropTypes from 'prop-types';

const FilterOptions = ({ data, setCaseFilter, caseFilter }) => {
  FilterOptions.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
    setCaseFilter: PropTypes.func.isRequired,
    caseFilter: PropTypes.string.isRequired,
  };

  return (
    <div className="container-md">
      <div className="filter-block">
        {data.Data.Options &&
          data.Data.Options.map(item => (
            <button
              key={item.Key}
              className={caseFilter === item.Key ? 'active' : ''}
              type="button"
              onClick={() => setCaseFilter(item.Key)}
            >
              {item.Value}
            </button>
          ))}
      </div>
    </div>
  );
};
export default FilterOptions;
