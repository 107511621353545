import React, { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

const DownloadForm = ({
  data,
  guid,
  currentCulture,
  closeDownLoad,
  closethankyou,
}) => {
  DownloadForm.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
    guid: PropTypes.string.isRequired,
    currentCulture: PropTypes.string.isRequired,
    closeDownLoad: PropTypes.func.isRequired,
    closethankyou: PropTypes.bool.isRequired,
  };
  const [tankyoumsg, setThankYou] = useState(closethankyou);
  const [closethanksmsg, setCloseThanks] = useState(closethankyou);
  return (
    <div className="downloadform-wrapper">
      <div className="downloadinner">
        {!tankyoumsg && (
          <div className="downloadform">
            <button
              className="btn-close"
              type="button"
              onClick={() => closeDownLoad()}
            />
            {data.Data.DownloadInfo &&
              data.Data.DownloadInfo.FormData.Title && (
                <h4 className="h4header">
                  {data.Data.DownloadInfo.FormData.Title}
                </h4>
              )}
            {data.Data.DownloadInfo &&
              data.Data.DownloadInfo.FormData.SubTitle && (
                <h5>{data.Data.DownloadInfo.FormData.SubTitle}</h5>
              )}
            <Formik
              initialValues={{ email: '', name: '' }}
              validate={values => {
                const errors = {};
                if (!values.email) {
                  errors.email = data.Data.DownloadInfo.FormData.ErrorMsgEmail;
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = data.Data.DownloadInfo.FormData.ErrorMsgEmail;
                }
                if (!values.name) {
                  errors.name = data.Data.DownloadInfo.FormData.ErrorMsgName;
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  axios({
                    method: 'post',
                    url: '/Umbraco/Api/Page/SendBlogDownloadMail',
                    data: {
                      blogId: guid,
                      firstName: values.name,
                      email: values.email,
                      culture: currentCulture,
                    },
                  });
                  setSubmitting(false);
                  setThankYou(true);
                  setCloseThanks(true);
                }, 400);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  {data.Data.DownloadInfo &&
                    data.Data.DownloadInfo.FormData.Name && (
                      <div className="form-row">
                        <span>{data.Data.DownloadInfo.FormData.Name}</span>
                        <input
                          type="text"
                          name="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                        />
                        <span className="error">
                          {errors.name && touched.name && errors.name}
                        </span>
                      </div>
                    )}
                  {data.Data.DownloadInfo &&
                    data.Data.DownloadInfo.FormData.Email && (
                      <div className="form-row">
                        <span>{data.Data.DownloadInfo.FormData.Email}</span>
                        <input
                          type="email"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                        <span className="error">
                          {errors.email && touched.email && errors.email}
                        </span>
                      </div>
                    )}
                  <button type="submit" disabled={isSubmitting}>
                    {data.Data.DownloadInfo &&
                      data.Data.DownloadInfo.FormData.SubmitButtonText}
                  </button>
                </form>
              )}
            </Formik>
          </div>
        )}
        {tankyoumsg && closethanksmsg && (
          <div className={`thankyouinner ${tankyoumsg ? 'fadeIn' : 'fadeOut'}`}>
            <button
              className="btn-close"
              type="button"
              onClick={() => closeDownLoad()}
            />
            {data.Data.DownloadInfo &&
              data.Data.DownloadInfo.ThankYouPage.Title && (
                <h4 className="h4header">
                  {data.Data.DownloadInfo.ThankYouPage.Title}
                </h4>
              )}
            {data.Data.DownloadInfo &&
              data.Data.DownloadInfo.ThankYouPage.Description && (
                <p>{data.Data.DownloadInfo.ThankYouPage.Description}</p>
              )}
          </div>
        )}
      </div>
    </div>
  );
};
export default DownloadForm;
