import React from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import ScrollAnimation from 'react-animate-on-scroll';
import Instagram from '../instagram/Instagram';
import ReactHtmlParser from 'react-html-parser';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Jobs = ({ data }) => {
  Jobs.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
  };
  if (isMobile) {
    return (
      <ScrollAnimation
        animateIn="fadeInUp"
        offset={100}
        duration={1.5}
        animateOnce
      >
        <div className="vacancy-container">
          <div>
            <h2 className="main-title">{data.Data.Title}</h2>
            <h3 className="sub-title">{data.Data.TagLine}</h3>
            <div className="vacancy-slider-block">
              <div className="vacancies slick-slide">
                <div>
                  {data.Data.Jobs &&
                    data.Data.Jobs.map(jobItem => (
                      <div
                        className="block"
                        key={jobItem.JobTitle && jobItem.JobTitle}
                      >
                        {jobItem.JobTitle ? (
                          <h3>{jobItem.JobTitle && jobItem.JobTitle}</h3>
                        ) : (
                          ''
                        )}

                        {jobItem.IntroductionText &&
                          ReactHtmlParser(jobItem.IntroductionText)}
                        {jobItem.Url ? (
                          <Link
                            to={jobItem.Url && jobItem.Url}
                            className="readmorelink-underline"
                          >
                            {jobItem.ReadMoreLinkText &&
                              jobItem.ReadMoreLinkText}
                          </Link>
                        ) : (
                          ''
                        )}
                      </div>
                    ))}
                </div>
                {data.Data.OverviewPage.Url && (
                  <Link
                    to={data.Data.OverviewPage.Url}
                    className="morevacancies-link"
                  >
                    {data.Data.OverviewPage.Name && data.Data.OverviewPage.Name}
                  </Link>
                )}
              </div>
              <div className="slick-wrapper">
                <Instagram data={data.Data.InstaBlock} />
              </div>
            </div>
          </div>
        </div>
      </ScrollAnimation>
    );
  }
  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      offset={100}
      duration={1.5}
      animateOnce
    >
      <div className="vacancy-container">
        <div>
          <div className="container-md">
            <h2 className="main-title">Vacatures</h2>
            <h3 className="sub-title">Wij zoeken jou</h3>
          </div>
          <div className="vacancy-slider-block">
            <div className="slick-wrapper">
              <Instagram data={data.Data.InstaBlock}>
                <div className="vacancies slick-slide">
                  {data.Data.Jobs &&
                    data.Data.Jobs.map(jobItem => (
                      <div
                        className="block"
                        key={jobItem.JobTitle && jobItem.JobTitle}
                      >
                        {jobItem.JobTitle ? (
                          <h3>{jobItem.JobTitle && jobItem.JobTitle}</h3>
                        ) : (
                          ''
                        )}
                        {jobItem.IntroductionText &&
                          ReactHtmlParser(jobItem.IntroductionText)}
                        {jobItem.Url ? (
                          <Link
                            to={jobItem.Url && jobItem.Url}
                            className="readmorelink-underline"
                          >
                            {jobItem.ReadMoreLinkText &&
                              jobItem.ReadMoreLinkText}
                          </Link>
                        ) : (
                          ''
                        )}
                      </div>
                    ))}
                  {data.Data.OverviewPage && (
                    <Link
                      to={
                        data.Data.OverviewPage.Url && data.Data.OverviewPage.Url
                      }
                      className="morevacancies-link"
                    >
                      {data.Data.OverviewPage.Name &&
                        data.Data.OverviewPage.Name}
                    </Link>
                  )}
                </div>
              </Instagram>
            </div>
          </div>
        </div>
      </div>
    </ScrollAnimation>
  );
};
export default Jobs;
