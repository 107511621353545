import React from 'react';
import PropTypes from 'prop-types';
import ScrollAnimation from 'react-animate-on-scroll';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import getResponsiveImageUrl from '../../utils/getResponsiveImageUrl';

const ImageSliderBlock = ({ data }) => {
  ImageSliderBlock.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
  };

  const settingsMulti = {
    className: 'center',
    centerMode: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 3,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          className: 'center',
          centerMode: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settingsSingle = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      offset={10}
      duration={1.5}
      animateOnce
      className="caseanimation-wrap"
    >
      <div className="imageslider-block">
        {String(data.Data.SliderStyle) === 'Multiple View' ? (
          <div className="multiple-view">
            <Slider {...settingsMulti}>
              {data.Data.ImageUrl &&
                data.Data.ImageUrl.map(item => (
                  <img
                    src={getResponsiveImageUrl(item)}
                    alt=""
                    key=""
                    className="slick-slide"
                  />
                ))}
            </Slider>
          </div>
        ) : (
          <div className="single-view">
            <Slider {...settingsSingle}>
              {data.Data.ImageUrl &&
                data.Data.ImageUrl.map(item => (
                  // <img
                  //   src={getResponsiveImageUrl(item)}
                  //   alt=""
                  //   key=""
                  //   className="slick-slide"
                  // />
                  <div className="singleviewimg">
                    <div
                      style={{
                        backgroundImage: `url(${getResponsiveImageUrl(item)})`,
                      }}
                    />
                  </div>
                ))}
            </Slider>
          </div>
        )}
      </div>
    </ScrollAnimation>
  );
};
export default ImageSliderBlock;
