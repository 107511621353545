import React, { useEffect, createRef, useState } from 'react';
import Pong from './pong.js';
import axios from 'axios';
import ScrollAnimation from 'react-animate-on-scroll';
import { isMobileOnly } from 'react-device-detect';
import PongGameForm from '../forms/PongGameForm';
// import PropTypes from 'prop-types';

const Ponggame = ({ data }) => {
  // Ponggame.propTypes = {
  //   data: PropTypes.objectOf(PropTypes.object).isRequired,
  // };

  const gameRef = createRef();

  const [scoreData, setScoreData] = useState([]);
  const [showHighScore, setShowHighScore] = useState(false);
  const [removeGameOver, setRemoveGameOver] = useState(false);


  useEffect(() => {
    if ((!isMobileOnly)&& data.Data.ShowPongGame) {
      Pong.init(gameRef.current)
    }
  }, []);



  const drawResults = () => {
    return (
      <div className="pong-result-wrp normal-text">
        {
          scoreData.map((scoreItem, index) => (
            console.log(scoreItem),
            <div className="pong-result"><span>{index + 1}.  {scoreItem.Name}</span><span>{scoreItem.Score}</span></div>
          ))
        }
      </div>
    );
  }

  const restartButtonHandler = () => {
    setShowHighScore(false);
    setRemoveGameOver(false);
    setTimeout(function(){
      Pong.gameInitialize();
    },500)
    
  };


  if ((!isMobileOnly) && data.Data.ShowPongGame) {
    return (
      <ScrollAnimation
        animateIn="fadeInUp"
        offset={10}
        duration={1.5}
        animateOnce
      >

        {console.log(data.Data.ShowPongGame)}
        <div className="game-area" id="gameArea" ref={gameRef} >
          <div id="countdown"></div>
          <div className="pong-content-wrp  pong-start-content active " id="pongStartContent">
            <div className="pong-intro-text-wrp">
              <div className="cross-bg-pong"></div>
              <div className="pong-heading">pong</div>
              {
                data.Data.Dictionary &&
                <p className="game-intro normal-text">{data.Data.Dictionary.HomeScreenDescription}</p>
              }

            </div>
            <button className="green-btn start-game" id="startGame">{data.Data.Dictionary && data.Data.Dictionary.HomeScreenButton}</button>
          </div>

          <div className={`pong-content-wrp pong-game-over ${removeGameOver ? 'inactive' : ''}`} id="pongGameOver">
            <input type="hidden" className="pong-results-heading" id="pongResultTop" />
            <div className="pong-heading">game over</div>
            {
              data.Data.Dictionary &&
              <p className="gameover-text normal-text"> {data.Data.Dictionary.GameScoreBeforeText} <span id="pongResultLabel"></span>. {data.Data.Dictionary.GameScoreAfterText}</p>
            }
            
            <div className="form-content-wrp">
              {data.Data.Dictionary && 
                <p className="small-text">{data.Data.Dictionary.FormDescription}</p>
              }
              <PongGameForm setShowHighScore={setShowHighScore} setScoreData={setScoreData} setRemoveGameOver={setRemoveGameOver} data={data} />
            </div>
            {data.Data.Dictionary && 
              <span className="play-again-text small-text">{data.Data.Dictionary.PlayAgainBeforeText} <span class="play-again" id="playAgain">{data.Data.Dictionary.PlayAgain}</span></span>
            }
          </div>

          <div className={`pong-content-wrp pong-high-scores ${showHighScore ? 'active' : 'inactive'}`} id="pongHighScores">
            <div className="cross-bg-score"></div>
            <div className="pong-heading">HIGH SCORES</div>
            {drawResults()}
            <button className="green-btn restart-game" onClick={restartButtonHandler}>{data.Data.Dictionary &&  data.Data.Dictionary.Restart}</button>
          </div>
          <div className="left-paddle" id="paddleLeft"></div>
          <div className="right-paddle" id="paddleRight"></div>
        </div >
      </ScrollAnimation>
    );
  } else {
    return (
      <div></div>
    );

  }
};
export default Ponggame;
