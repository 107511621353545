import React from 'react';
import PropTypes from 'prop-types';

const CaseResults = ({ data }) => {
  CaseResults.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
  };
  return (
    data.Data.Testimonial &&
    data.Data.Author && (
      <div
        className={`case-result-block ${
          data.Data.ColorTheme === 'Light' ? 'white-theme' : ''
        }`}
        style={{ backgroundColor: data.Data.BackgroundColor }}
      >
        <div className="container-md-sm">
          <div className="top">
            <div className="testimonial-block">
              <div className="testimonial">
                {data.Data.Testimonial && data.Data.Testimonial}
              </div>
              <div className="author">
                {data.Data.Author && data.Data.Author}
              </div>
            </div>
            <div className="resolution-block">
              <div className="title">{data.Data.Title && data.Data.Title}</div>
              <p>{data.Data.Description && data.Data.Description}</p>
            </div>
          </div>
          <div className="bottom">
            {data.Data.Results.map((item, index) => (
              <div className={`block block-${index + 1}`}>
                <div className="amount">{item.Amount}</div>
                <div className="description">{item.Description}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  );
};
export default CaseResults;
