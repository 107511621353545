import React from 'react';
import PropTypes from 'prop-types';
import ScrollAnimation from 'react-animate-on-scroll';

const QuoteText = ({ data }) => {
  QuoteText.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
  };
  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      offset={10}
      duration={1.5}
      animateOnce
    >
      {data.Data.Text && (
        <div className="quote-text-wrap">{data.Data.Text}</div>
      )}
    </ScrollAnimation>
  );
};
export default QuoteText;
