import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

const CaseContenBlock = ({ data }) => {
  CaseContenBlock.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
  };
  return (
    <div className="interm-textblock">
      <div className="container-sm">
        <h2>{data.Data.Title && data.Data.Title}</h2>
        {ReactHtmlParser(data.Data.Description && data.Data.Description)}
      </div>
    </div>
  );
};
export default CaseContenBlock;
