import React from 'react';
import PropTypes from 'prop-types';
import getResponsiveImageUrl from '../../utils/getResponsiveImageUrl';

const WideImageBlock = ({ data }) => {
  WideImageBlock.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
  };

  return (
    <div className="wideimage-block-outer">
      <div className="wideimage-block">
        {getResponsiveImageUrl(data.Data.ImageUrl) && (
          <img src={getResponsiveImageUrl(data.Data.ImageUrl)} alt="" />
        )}
      </div>
    </div>
  );
};
export default WideImageBlock;
