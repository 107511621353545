import React, { useEffect, useState } from 'react';
import { Link } from 'framework7-react';
import axios from 'axios';
import PropTypes from 'prop-types';
import ScrollAnimation from 'react-animate-on-scroll';
import getResponsiveImageUrl from '../../utils/getResponsiveImageUrl';

const PageNotFound = ({ pageNotFoundId, currentLanguage }) => {
  PageNotFound.propTypes = {
    pageNotFoundId: PropTypes.string.isRequired,
    currentLanguage: PropTypes.string.isRequired,
  };
  const [title, setTitle] = useState([]);
  const [subtitle, setSubTitle] = useState([]);
  const [image, setImage] = useState([]);
  useEffect(() => {
    // setTimeout(() => {
    //   setTransition(false);
    // }, 3000);
    axios
      .get('/Umbraco/Api/Page/GetPageContent', {
        params: {
          culture: currentLanguage,
          pageId: pageNotFoundId,
        },
      })
      .then(response => {
        if (response.status === 200) {
          setTitle(response.data.Template[0].Data.Title);
          setSubTitle(response.data.Template[0].Data.Subtitle);
          setImage(response.data.Template[0].Data.Image);
        }
      });
  }, [pageNotFoundId]);
  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      offset={10}
      duration={1.5}
      animateOnce
    >
      <div className="container-md pagenot-found">
        <div className="inner">
          <h2 className="main-title">{title}</h2>
          <h3 className="sub-title">{subtitle}</h3>
          <a href="/" className="readmorelink-underline">
            Take me back to the safe zone
          </a>
        </div>
        <img src={getResponsiveImageUrl(image)} alt="" key="" />
      </div>
    </ScrollAnimation>
  );
};
export default PageNotFound;
