import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ScrollAnimation from 'react-animate-on-scroll';

const Services = ({ data }) => {
  const [serviceCount, setServiceCount] = useState([]);
  useEffect(() => {
    setServiceCount(data.Data.Items.length);
  });
  Services.propTypes = {
    data: PropTypes.string.isRequired,
  };
  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      offset={10}
      duration={1.5}
      animateOnce
    >
      <div
        className={`services-container ${
          serviceCount < 4 ? 'make-center' : ''
        }`}
      >
        <div className="container-md">
          {data.Data.Title ? (
            <h2 className="main-title">{data.Data.Title && data.Data.Title}</h2>
          ) : (
            ''
          )}
          {data.Data.TagLine ? (
            <h3 className="sub-title">
              {data.Data.TagLine && data.Data.TagLine}
            </h3>
          ) : (
            ''
          )}
          <div className="services">
            {data.Data.Items.map(service => (
              <div className="block" key={service.Title && service.Title}>
                {service.Title.length ? (
                  <h3>{service.Title && service.Title}</h3>
                ) : (
                  ''
                )}
                {service.Description ? (
                  <p>{service.Description && service.Description}</p>
                ) : (
                  ''
                )}
                {service.Link && service.Link.Type === 2 ? (
                  <a
                    target="_blank"
                    href={service.Link.Url && service.Link.Url}
                    className="readmorelink-underline"
                    rel="noopener noreferrer"
                  >
                    {service.Link.Name && service.Link.Name}
                  </a>
                ) : (
                  ''
                )}
                {service.Link && service.Link.Type === 0 ? (
                  <Link
                    to={service.Link.Url && service.Link.Url}
                    className="readmorelink-underline"
                  >
                    {service.Link.Name && service.Link.Name}
                  </Link>
                ) : (
                  ''
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </ScrollAnimation>
  );
};
export default Services;
