import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import ScrollAnimation from 'react-animate-on-scroll';

const Homecontent = ({ data }) => {
  Homecontent.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
  };

  return (
    <ScrollAnimation animateIn="fadeIn" duration={1} animateOnce>
      <div className="home-container">
        <div className="container-md">
          <div className="dashcircle">
            <div className="hm-header-txt">
              {data.Data.Slogan ? (
                <div
                  className="hm-fancy-title"
                  key={data.Data.Slogan && data.Data.Slogan}
                >
                  {data.Data.Slogan && data.Data.Slogan}
                </div>
              ) : (
                ''
              )}
              <div className="main-content">
                {data.Data.Title ? (
                  <div
                    className="hm-main-title"
                    key={data.Data.Title && data.Data.Title}
                  >
                    {data.Data.Title && data.Data.Title}
                  </div>
                ) : (
                  ''
                )}
                {ReactHtmlParser(
                  data.Data.Description && data.Data.Description
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ScrollAnimation>
  );
};
export default Homecontent;
