import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import getResponsiveImageUrl from '../../utils/getResponsiveImageUrl';

const CaseDetailsTop = ({ data, passMenuTheme }) => {
  CaseDetailsTop.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
    passMenuTheme: PropTypes.func.isRequired,
  };
  passMenuTheme(data.Data.MenuTheme.toLowerCase());
  return (
    <div className="casedetails-top">
      <div className="container-max">
        <div className="main-image-block">
          {getResponsiveImageUrl(data.Data.Image) && (
            <img src={getResponsiveImageUrl(data.Data.Image)} alt="" />
          )}
        </div>
        <div
          className={`casemain-desc angle-line ${
            data.Data.ColorTheme === 'Light' ? 'white-theme' : ''
          }`}
          style={{ backgroundColor: data.Data.BackgroundColor }}
        >
          {data.Data.ProjectName && (
            <div className="casedetail-head">{data.Data.ProjectName}</div>
          )}
          <div className="content">
            <div className="box" />
            {data.Data.Description && (
              <p>{ReactHtmlParser(data.Data.Description)}</p>
            )}
          </div>
          <div className="client-details">
            {data.Data.ClientLogo && (
              <div className="logo-box">
                <img src={data.Data.ClientLogo} alt="" />
              </div>
            )}
            {data.Data.Disciplines && (
              <div className="jobdone">
                <h3>{data.Data.Disciplines_Title}</h3>
                <p>{data.Data.Disciplines && data.Data.Disciplines}</p>
              </div>
            )}

            {data.Data.TogetherWith && (
              <div className="jobdoneby">
                <h3>{data.Data.TogetherWith_Title}</h3>
                {data.Data.TogetherWith && <p>{data.Data.TogetherWith}</p>}
              </div>
            )}
            {data.Data.ProjectUrl && (
              <div className="url">
                <a
                  href={data.Data.ProjectUrl.Url}
                  className="readmorelink-underline"
                >
                  {data.Data.ProjectUrl.Name && data.Data.ProjectUrl.Name}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CaseDetailsTop;
