import React from 'react';
import PropTypes from 'prop-types';
import getResponsiveImageUrl from '../../utils/getResponsiveImageUrl';

const ContactBlock = ({ data }) => {
  ContactBlock.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
  };
  return (
    <div className="contact-block">
      <div className="container-md contact-boxes-circle">
        {data.Data.Title && <h2 className="main-title">{data.Data.Title}</h2>}
        {data.Data.TagLine && (
          <h3 className="sub-title">{data.Data.TagLine}</h3>
        )}
        <div className="contact-person">
          {data.Data.ContactPerson.FirstName && (
            <div className="contactname">
              <span>
                {data.Data.ContactPerson.FirstName}
                {data.Data.ContactPerson.LastName}
              </span>
            </div>
          )}
          <div className="twocol-row">
            {data.Data.ContactPerson.JobTitle && (
              <div className="position">
                <span>{data.Data.ContactPerson.JobTitle}</span>
              </div>
            )}
            {data.Data.ContactPerson.PhoneNumber && (
              <div className="phone">{data.Data.ContactPerson.PhoneNumber}</div>
            )}
          </div>
          {data.Data.ContactPerson.Email && (
            <div className="email">
              <a href={`mailto:${data.Data.ContactPerson.Email}`}>
                {data.Data.ContactPerson.Email}
              </a>
            </div>
          )}
          {getResponsiveImageUrl(data.Data.ContactPerson.Image) && (
            <div
              className="imageblock"
              style={{
                backgroundImage: `url(${getResponsiveImageUrl(
                  data.Data.ContactPerson.Image
                )})`,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default ContactBlock;
