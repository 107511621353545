import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import Masonry from 'react-masonry-component';
import ScrollAnimation from 'react-animate-on-scroll';
import getResponsiveImageUrl from '../../utils/getResponsiveImageUrl';

const masonryOptions = {
  transitionDuration: '0.5s',
  isAnimated: true,
  horizontalOrder: true,
  fitWidth: true,
};
const imagesLoadedOptions = { background: '.my-bg-image-el' };

const PinnedBlog = ({ data }) => {
  const perPage = 8;
  const [paginCount, setPaginCount] = useState(1);
  const [showLoadMore, setLoadMore] = useState(true);
  PinnedBlog.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
  };
  const loadMore = () => {
    setPaginCount(paginCount + 1);
    if ((paginCount + 1) * perPage >= data.Data.Blogs.length) {
      setLoadMore(false);
    }
  };
  useEffect(() => {
    if (perPage > data.Data.Blogs.length) {
      setLoadMore(false);
    }
  }, []);
  const childElements = () => {
    return (
      data.Data.Blogs &&
      data.Data.Blogs.slice(0, paginCount * perPage).map(blogitem => (
        <div className="blog-item">
          <div className="blogitem-img">
            <Link to={blogitem.Url}>
              <img
                src={
                  getResponsiveImageUrl(blogitem.Image) &&
                  getResponsiveImageUrl(blogitem.Image)
                }
                alt=""
              />
            </Link>
          </div>
          <div className="blog-item-content">
            {blogitem.Title && (
              <h4 className="h4header">
                <Link to={blogitem.Url}>{blogitem.Title}</Link>
              </h4>
            )}
            <div className="blog-details">
              {blogitem.DisplayDate && <span>{blogitem.DisplayDate}</span>}
              {blogitem.ReadingTime && (
                <span>
                  &nbsp; - &nbsp; ± &nbsp;
                  {blogitem.ReadingTime}
                </span>
              )}
            </div>
            {blogitem.IntroText && ReactHtmlParser(blogitem.IntroText)}
            {blogitem.ReadMoreText && blogitem.Url && (
              <Link to={blogitem.Url} className="readmorelink-underline">
                {blogitem.ReadMoreText}
              </Link>
            )}
          </div>
        </div>
      ))
    );
  };
  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      offset={10}
      duration={1.5}
      animateOnce
    >
      <div className="container-md">
        <div
          className={`pinned-blog ${data.Data.PinnedBlog.BackgroundBrush &&
            data.Data.PinnedBlog.BackgroundBrush} ${data.Data.PinnedBlog
            .BackgroundPattern && data.Data.PinnedBlog.BackgroundPattern}`}
        >
          <Link
            to={data.Data.PinnedBlog.Blog.Url && data.Data.PinnedBlog.Blog.Url}
          >
            <img
              src={
                data.Data.PinnedBlog.Blog &&
                getResponsiveImageUrl(data.Data.PinnedBlog.Blog.Image)
              }
              alt=""
            />
          </Link>
        </div>
        {data.Data.PinnedBlog.Blog && (
          <div className="blogoverview-content">
            {data.Data.PinnedBlog.Blog.Title && (
              <h3 className="h3header">
                <Link
                  to={
                    data.Data.PinnedBlog.Blog.Url &&
                    data.Data.PinnedBlog.Blog.Url
                  }
                >
                  {data.Data.PinnedBlog.Blog.Title}
                </Link>
              </h3>
            )}
            <div className="blog-details">
              {data.Data.PinnedBlog.Blog.DisplayDate && (
                <span>{data.Data.PinnedBlog.Blog.DisplayDate}</span>
              )}
              {data.Data.PinnedBlog.Blog.ReadingTime && (
                <span>
                  &nbsp; - &nbsp; ± &nbsp;
                  {data.Data.PinnedBlog.Blog.ReadingTime}
                </span>
              )}
            </div>
            {data.Data.PinnedBlog.Blog.IntroText &&
              ReactHtmlParser(data.Data.PinnedBlog.Blog.IntroText)}
            {data.Data.PinnedBlog.Blog.ReadMoreText &&
              data.Data.PinnedBlog.Blog.Url && (
                <Link
                  to={
                    data.Data.PinnedBlog.Blog.Url &&
                    data.Data.PinnedBlog.Blog.Url
                  }
                  className="readmorelink-underline"
                >
                  {data.Data.PinnedBlog.Blog.ReadMoreText &&
                    data.Data.PinnedBlog.Blog.ReadMoreText}
                </Link>
              )}
          </div>
        )}

        <div className="bloglisting">
          <Masonry
            className="my-gallery-class" // default ''
            elementType="div" // default 'div'
            options={masonryOptions} // default {}
            disableImagesLoaded={false} // default false
            updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
            imagesLoadedOptions={imagesLoadedOptions}
          >
            {childElements()}
          </Masonry>
          {showLoadMore && (
            <button
              type="button"
              onClick={() => loadMore()}
              className="readmore"
            >
              {data.Data.LoadMoreText}
            </button>
          )}
        </div>
      </div>
    </ScrollAnimation>
  );
};
export default PinnedBlog;
