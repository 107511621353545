import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ScrollAnimation from 'react-animate-on-scroll';
import ReactHtmlParser from 'react-html-parser';
import ContactForm from '../forms/ContactForm';

const ContactPage = ({ data, guid, currentCulture }) => {
  ContactPage.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
    guid: PropTypes.string.isRequired,
    currentCulture: PropTypes.string.isRequired,
  };
  const [thankyoupage, setThankYouPage] = useState(false);

  const changeThankyouPage = e => {
    setThankYouPage(e);
    console.log(thankyoupage);
  };
  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      offset={10}
      duration={1.5}
      animateOnce
    >
      <div className="contact">
        <div className="container-md">
          {data.Data.Title && !thankyoupage && (
            <h1
              className={`common-header ${data.Data.Brush &&
                data.Data.Brush} ${data.Data.BackgroundPattern &&
                data.Data.BackgroundPattern}`}
            >
              {data.Data.Title}
            </h1>
          )}
          {data.Data.ThankYouText && thankyoupage && (
            <h1
              className={`common-header ${data.Data.Brush &&
                data.Data.Brush} ${data.Data.BackgroundPattern &&
                data.Data.BackgroundPattern}`}
            >
              {ReactHtmlParser(data.Data.ThankYouText)}
            </h1>
          )}
          {!thankyoupage && (
            <ContactForm
              data={data}
              guid={guid}
              currentCulture={currentCulture}
              changeThankyouPage={changeThankyouPage}
            />
          )}
        </div>
      </div>
    </ScrollAnimation>
  );
};
export default ContactPage;
