import React, { useContext } from 'react';
import SettingsContext from '../../context/SettingsContext';
import Socialicons from './Socialicons';

const Address = () => {
  const settings = useContext(SettingsContext);
  return (
    <div className="address-container">
      {settings.footerArray.PhoneNumber && (
        <span className="telephone">
          <a href={`tel:${settings.footerArray.PhoneNumber}`}>
            {settings.footerArray.PhoneNumber &&
              settings.footerArray.PhoneNumber}
          </a>
        </span>
      )}
      {settings.footerArray.Email && (
        <a href={`mailto:${settings.footerArray.Email}`}>
          {settings.footerArray.Email && settings.footerArray.Email}
        </a>
      )}
      <address>
        <span className="addressline-1">
          {settings.addressArray.Line1 && settings.addressArray.Line1}
        </span>
        <span className="addressline-2">
          {settings.addressArray.Line2 && settings.addressArray.Line2}
        </span>
        <span className="addressline-3">
          {settings.addressArray.Line3 && settings.addressArray.Line3}
        </span>
      </address>
      <span className="location">
        {settings.addressArray.Location && settings.addressArray.Location}
      </span>
      <Socialicons />
    </div>
  );
};
export default Address;
