import React from 'react';
import PropTypes from 'prop-types';
import ScrollAnimation from 'react-animate-on-scroll';

const Clients = ({ data }) => {
  Clients.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
  };
  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      offset={10}
      duration={1.5}
      animateOnce
    >
      <div className="client-container">
        <div className="container-md">
          <h2 className="main-title">Klanten</h2>
          <h3 className="sub-title">Merken die we versterken</h3>
          <div className="logo-container">
            {data.Data.Logos &&
              data.Data.Logos.map(item => (
                <div className="item" key={item.Name}>
                  <img
                    src={item.Logo && item.Logo}
                    alt={item.Name && item.Name}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </ScrollAnimation>
  );
};
export default Clients;
