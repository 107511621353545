const returnDevice = () => {
  const iOS =
    !!navigator.platform && /MacIntel|Macintosh/.test(navigator.platform);
  const ipad = !!navigator.platform && /iPad|iPod/.test(navigator.platform);
  const iphone = !!navigator.platform && /iPhone|iPod/.test(navigator.platform);
  if (iOS) {
    return 'appledevice';
  }
  if (ipad) {
    return 'appleipad';
  }
  if (iphone) {
    return 'applemobile';
  }
  return '';
};
export default returnDevice;
