import React from 'react';
import PropTypes from 'prop-types';
import getResponsiveImageUrl from '../../utils/getResponsiveImageUrl';

const TwoBlock = ({ data }) => {
  TwoBlock.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
  };

  return (
    <div>
      <div className="three-block-wrapper">
        <div className="two-block">
          <div className="block-wrap">
            <div
              className="block"
              style={{
                backgroundImage: `url(${getResponsiveImageUrl(data[0])})`,
              }}
            />
          </div>
          <div className="block-wrap">
            <div
              className="block"
              style={{
                backgroundImage: `url(${getResponsiveImageUrl(data[1])})`,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default TwoBlock;
