import React, { useContext } from 'react';
import SettingsContext from '../../context/SettingsContext';
import Address from './Address';

const Footer = () => {
  const settings = useContext(SettingsContext);
  return (
    <footer>
      {settings.footerArray && (
        <div className="footer-wrapper">
          <div className="container-md officeimage">
            <h2 className="main-title">
              {settings.footerArray.Title && settings.footerArray.Title}
            </h2>
            <h3 className="sub-title">
              {settings.footerArray.Subtitle && settings.footerArray.Subtitle}
            </h3>
            <Address />
          </div>
        </div>
      )}
    </footer>
  );
};
export default Footer;
