import React, { useEffect,  useState } from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

const PongGameForm = ({ setShowHighScore, setScoreData, setRemoveGameOver, data }) => {

    const [nameError, setNameError] = useState([]);
    const [emailError, setEmailError] = useState([]);
    
    useEffect(() => {
        if(data.Data.Dictionary){
            setNameError(data.Data.Dictionary.ErrorMsgName);
            setEmailError(data.Data.Dictionary.ErrorMsgEmail); 
        }    
    }, []);

    return (
        <div>
            <Formik
                initialValues={{ name: '' , email: '' , pongResult: null, }}
                validate={values => {
                    
                    let errors = {};
                    if (!values.name) {
                        errors.name = nameError;
                    }
                    if (!values.email) {
                        errors.email = emailError;
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                        errors.email = 'Invalid email address';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                        axios({
                            method: 'post',
                            url: '/Umbraco/Api/PongGame/AddScore',
                            params: {
                                name: values.name,
                                email: values.email,
                                score: window.myscore
                            },
                        })
                            .then(function (response) {
                                setScoreData(response.data);
                                setRemoveGameOver(true);
                                setTimeout(function(){
                                    setShowHighScore(true);
                                },500)
                                resetForm();
                            });
                        setSubmitting(false);
                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <div className="form-group">
                            {
                                data.Data.Dictionary &&
                                <label className="very-small-text">{data.Data.Dictionary.Name}</label>
                            }

                            <Field className="form-input" type="text" name="name" />
                            <ErrorMessage className="form-error" name="name" component="span" />
                        </div>
                        <div className="form-group">
                            {
                                data.Data.Dictionary &&
                                <label className="very-small-text">{data.Data.Dictionary.Email}  </label>
                            }

                            <Field className="form-input" type="email" name="email" />
                            <ErrorMessage className="form-error" name="email" component="span" />
                        </div>

                        <input type="submit" className="green-btn submit-results" value={data.Data.Dictionary && data.Data.Dictionary.FormButton} disabled={isSubmitting} id="pongSubmitResults" />
                    </Form>
                )}
            </Formik>
        </div>
    );

};

export default PongGameForm;