import React from 'react';
import PropTypes from 'prop-types';
import ScrollAnimation from 'react-animate-on-scroll';
import { BrowserView, MobileView, TabletView } from 'react-device-detect';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import getResponsiveImageUrl from '../../utils/getResponsiveImageUrl';

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToScroll: 3,
  slidesToShow: 3,
  autoplay: false,
  autoplaySpeed: 4000,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: 'unslick',
    },
  ],
};
const TeamHeader = ({ data }) => {
  TeamHeader.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
  };
  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      offset={10}
      duration={1.5}
      animateOnce
    >
      <div className="team-header">
        <div className="container-md">
          <div className="personal-block">
            <div
              className="imageblock"
              style={{
                backgroundImage: `url(${getResponsiveImageUrl(
                  data.Data.Image
                )})`,
              }}
            />
            <div className="details-block">
              <div className="name">
                <span>
                  {data.Data.FirstName}
                  <br />
                  {data.Data.LastName}
                </span>
              </div>
              <div className="twocol-row">
                <div className="position">
                  {data.Data.JobTitle && data.Data.JobTitle}
                </div>
                <div className="phone">
                  {data.Data.PhoneNumber && data.Data.PhoneNumber}
                </div>
              </div>
              <div className="email">
                <a href={`mailto:${data.Data.Email}`}>
                  {data.Data.Email && data.Data.Email}
                </a>
              </div>
            </div>
          </div>
          {data.Data.PersonalPhotos.length !== 0 && (
            <div className="mobileimages">
              {data.Data.PersonalPhotos.map(item => (
                <img
                  src={getResponsiveImageUrl(item)}
                  alt=""
                  key=""
                  className="slick-slide"
                />
              ))}
            </div>
          )}
        </div>
        {data.Data.PersonalPhotos.length !== 0 && (
          <div className="imageslider">
            <Slider {...settings}>
              {data.Data.PersonalPhotos.map(item => (
                <img
                  src={getResponsiveImageUrl(item)}
                  alt=""
                  key=""
                  className="slick-slide"
                />
              ))}
            </Slider>
          </div>
        )}
      </div>
    </ScrollAnimation>
  );
};
export default TeamHeader;
